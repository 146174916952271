import Vue from 'vue'
import {baseConsoleRoutes} from '~bac/router'

Vue.use(VueRouter)

export default new VueRouter({
	mode: 'history',
	routes: [{
		path: '/test',                                              //首页
		name: 'test',
		component: resolve => require(['~tcb/views/test'], resolve),
	},
		{
			path: '/',                                              //首页
			name: 'index',
			component: resolve => require(['~tcb/views/index'], resolve),

		},
		{
			path: '/downloadSection',                                              //首页
			name: 'downloadSection',
			component: resolve => require(['~tcb/views/index/downloadSection/index'], resolve),
		},
		{
			path: '/activitys',                                              //首页--活动
			name: 'activitys',
			component: resolve => require(['~tcb/views/index/activitys/index'], resolve),
			children: []
		},
		{
			path: '/activity',                                  //首页-----活动 （修改）
			name: 'activity',
			component: resolve => require(['~tcb/views/activity/index'], resolve)
		},
		{
			path: '/memberShip',                                              //申请入会
			name: 'memberShip',
			component: resolve => require(['~tcb/views/index/memberShip/index'], resolve),
			children: [
				{
					path: '/',
					name: 'applyFor',
					component: resolve => require(['~tcb/views/index/memberShip/flow'], resolve)
				}, {
					path: '/memberShip/applyFor',
					name: 'applyFor',
					component: resolve => require(['~tcb/views/index/memberShip/applyFor'], resolve)
				}]
		},
		{
			path: '/college/course',                                  //物贸学院
			name: 'college',
			component: resolve => require(['~tcb/views/college/index'], resolve)
		},
		{
			path: '/college/courseSet',                                  //物贸学院
			name: 'courseSet',
			component: resolve => require(['~tcb/views/college/courseSet/index'], resolve)
		},
		{
			path: '/college_detail',                                  //物贸学院 详情
			name: 'college_detail',
			component: resolve => require(['~tcb/views/college/detail'], resolve)
		},
		{
			path: '/positionDetail',                                //职位详情
			name: 'positionDetail',
			component: resolve => require(['~tcb/views/position/detail'], resolve)
		},
		{
			path: '/survey',                                              //协会概况
			name: 'survey',
			component: resolve => require(['~tcb/views/survey'], resolve),
			children: [{
				path: '/',
				name: 'brief',
				component: resolve => require(['~tcb/views/survey/brief'], resolve)
			}, {
				path: '/survey/director',
				name: 'director',
				component: resolve => require(['~tcb/views/survey/director'], resolve)
			}, {
				path: '/survey/framework',
				name: 'framework',
				component: resolve => require(['~tcb/views/survey/framework'], resolve)
			}, {
				path: '/survey/member',
				name: 'member',
				component: resolve => require(['~tcb/views/survey/member'], resolve)
			}, {
				path: '/survey/constitution',
				name: 'constitution',
				component: resolve => require(['~tcb/views/survey/constitution'], resolve)
			}, {
				path: "/survey/memorabilia",
				name: 'memorabilia',
				component: resolve => require(['~tcb/views/survey/memorabilia'], resolve)
			}, {
				path: "/survey/structure",
				name: 'structure',
				component: resolve => require(['~tcb/views/survey/structure'], resolve)
			}]
		}, {
			path: '/companyCatalog',//会员名录
			name: 'companyCatalog',
			component: resolve => require(['~tcb/views/company/companyCatalog'], resolve)
		}, {
			path: '/companyContactList',
			name: 'companyContactList',
			component: resolve => require(['~tcb/views/company/companyContactList'], resolve)
		}, {
			path: '/companyDetail',
			name: 'companyDetail',
			component: resolve => require(['~tcb/views/company/companyDetail'], resolve)
		}, {
			path: '/sign',
			name: 'sign',
			component: resolve => require(['~tcb/views/users/sign'], resolve),
			meta: {
				layout: 'sign'
			}
		},
		{
			path: '/forgetPassword',
			name: 'forgetPassword',
			component: resolve => require(['~tcb/views/users/forgetPassword'], resolve),
		},
		{
			path: '/resetPassword',
			name: 'resetPassword',
			component: resolve => require(['~tcb/views/users/resetPassword'], resolve),
		},
		{
			path: '/signUp',
			name: 'signUp',
			component: resolve => require(['~tcb/views/users/signUp'], resolve),
			meta: {
				layout: 'sign'
			}
		}, {
			path: '/activeAccount',
			name: 'activeAccount',
			component: resolve => require(['~tcb/views/users/activeAccount'], resolve),
		}, {
			path: '/memberCenter',
			name: 'memberCenter',
			component: resolve => require(['~tcb/views/memberCenter'], resolve)
		},
		{
			path: "/creditAssessment",//信用评估页面
			name: "creditAssessment",
			component: resolve => require(['~tcb/views/creditAssessment'], resolve)
		},
		{
			path: "/creditDetail",//信用评估详情页面
			name: "creditDetail",
			component: resolve => require(['~tcb/views/creditAssessment/detail'], resolve)
		},
		{
			path: "/basicInfo",//完善信息
			name: "basicInfo",
			component: resolve => require(['~tcb/views/users/basicInfo'], resolve)
		},
		{
			path: "/contactUs",//完善信息
			name: "contactUs",
			component: resolve => require(['~tcb/views/contactUs/index'], resolve)
		},
		{
			path: "/detail",//完善信息
			name: "detail",
			component: resolve => require(['~tcb/views/index/detail'], resolve)
		},
		{
			path: "/activityDetail",//首页活动详情
			name: "activityDetail",
			component: resolve => require(['~tcb/views/index/detail/activityDetail'], resolve)
		},
		{
			path: "/memberInfo",
			name: "memberInfo",
			component: resolve => require(['~tcb/views/index/detail/companyInfo'], resolve)
		},
		{    // 风险
			path: "/warningList",
			name: "warningList",
			component: resolve => require(['~tcb/views/warningList/index'], resolve)
		},
		{
			path: '/handleInitialParams',
			name: 'handleInitialParams',
			meta: {
				layout: 'empty'
			},
			component: resolve => require(['~tcb/views/users/initPage'], resolve)
		},
		{
			path: '/member/memberDirectory/:id',//会员专区-会员名录
			name: 'memberDirectory',
			component: resolve => require(['~tcb/views/member/memberDirectory'], resolve)
		},
		{
			path: '/member/interests/vice',//会员专区-会员名录-副会长单位
			name: 'vice',
			component: resolve => require(['~tcb/views/member/interests/vice'], resolve)
		},
		{
			path: '/member/interests/standing',//会员专区-会员名录-常务理事单位
			name: 'standing',
			component: resolve => require(['~tcb/views/member/interests/standing'], resolve)
		},
		{
			path: '/member/interests/director',//会员专区-会员名录-理事单位
			name: 'director',
			component: resolve => require(['~tcb/views/member/interests/director'], resolve)
		},
		{
			path: '/member/interests/member',//会员专区-会员名录-会员单位
			name: 'member',
			component: resolve => require(['~tcb/views/member/interests/member'], resolve)
		},
		{
			path: '/centre/party/branch',//党支部架构
			name: 'branch',
			component: resolve => require(['~tcb/views/centre/party/branch'], resolve)
		},
		{
			path: '/talents/recruitment',//招聘信息
			name: 'recruitment',
			component: resolve => require(['~tcb/views/talents/recruitment'], resolve)
		},
		{
			path: '/talents/library',//人才库
			name: 'library',
			component: resolve => require(['~tcb/views/talents/library'], resolve)
		},
		{
			path: '/contactUs/association',//协会联系方式
			name: 'library',
			component: resolve => require(['~tcb/views/contactUs/association'], resolve)
		},
		{
			path: '/contactUs/message',//留言箱
			name: 'association',
			component: resolve => require(['~tcb/views/contactUs/message'], resolve)
		},
		{
			path: '/preClass/applyFor',//申请预归类
			name: 'classApplyFor',
			component: resolve => require(['~tcb/views/preClass/applyFor'], resolve)
		},
		{
			path: '/education', //教育培训
			name: 'education',
			component: resolve => require(['~tcb/views/education/index'], resolve),
		},
		{
			path:'/educationDetail', //教育培训--详情
			name: 'educationDetail',
			component: resolve => require(['~tcb/views/education/detail'], resolve),
		},

	].concat(baseConsoleRoutes).concat([{
		path: "**",
		redirect: "/"
	}]),
	scrollBehavior(to, from, savedPosition) {
		return {x: 0, y: 0}
	}
})
