import { commonEnumFormat } from '~/basePlugins/filters'

import { creditClass } from '~tcb/utils/enumValue'                 //信用等级
export const creditClassFormat = commonEnumFormat(creditClass)

import { unitType } from '~tcb/utils/enumValue'                 //单位类型
export const unitTypeFormat = commonEnumFormat(unitType)

import { getCityNameById } from '@/baseUtils/AddressConfig'
export const getCityNameByCityId = getCityNameById//根据城市id获取城市名

import { levelRequire } from '~scr/utils/enumValue'                 //学历要求
export const levelRequireFormat = commonEnumFormat(levelRequire)

import { yearList } from '~tcb/utils/enumValue'                 //单位类型
export const yearListFormat = commonEnumFormat(yearList)
import { staffsizeList } from '~tcb/utils/enumValue'                 //信用等级
export const staffsizeFormat = commonEnumFormat(staffsizeList);

import {setYears} from '~tcb/utils/enumValue' //物贸联合会成立年限
export const trfYearFormat=commonEnumFormat(setYears)
//工作经验
import { experience } from '~scr/utils/enumValue'
export const experienceFormat=commonEnumFormat(experience)

//订单状态
import { orderStatus } from '~tcb/utils/enumValue'
export const orderStatusFormat = commonEnumFormat(orderStatus)

//支付方式过滤器
import { paymentMethod } from '~tcb/utils/enumValue'
export const paymentMethodFormat = commonEnumFormat(paymentMethod)

// 预归类金额
import { preClassificationMoney } from '~tcb/utils/enumValue'
export function preClassificationMoneyFormat(number, member=false) {
    if (!number && number !== 0) {
        return
    }
    let current = preClassificationMoney.find(item => {
        return item.number > number
    });
    if (current) {
        return member ? current.money * current.member_discount : current.money
    } else {
        return 
    }
}