<template>
  <div class="navMenu">
    <div class="topHeader content_area">
      <div class="topHeader-left">
        <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tcba/logo/logo.png" alt="logo"/>
      </div>
      <div class="topHeader-center">
        <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tcba/home/topheadertext.png"
             alt="text"/>
      </div>
      <div class="topHeader-right">
          <span v-if="!IS_LOGIN" class="right-top">
                  <router-link to="/sign" class="goLogin">
                    <span>登录</span> </router-link
                  >
             <el-divider direction="vertical"></el-divider>
                  <router-link to="/signUp" class="goRegister">
                    <span>注册</span>
                  </router-link>
                </span>
        <span v-else class="right-top">
                  <el-popover
                      placement="bottom-start"
                      trigger="click"
                      v-model="visible"
                  >
                    <div class="navMenuselfAccountInfo">
                      <p class="textOverflow">
                        姓名：{{
                          USER_INFO.name_en
                              | priorFormat(USER_INFO.name_zh, LOCALE)
                        }}
                      </p>
                      <p class="textOverflow">
                        职位：{{
                          USER_INFO.job_title_en
                              | priorFormat(USER_INFO.job_title_zh, LOCALE)
                        }}
                      </p>
                      <p class="textOverflow">
                        邮箱：{{ USER_INFO.email | textFormat }}
                      </p>
                      <p class="textOverflow">
                        手机号：{{ USER_INFO.mobile | textFormat }}
                      </p>
                    </div>

                    <div class="navMenuselfAccountInfoBtn">
                      <el-button
                          type="danger"
                          size="small"
                          @click="
                          visible = false;
                          logOut();
                        "
                      >退出登录</el-button
                      >
                    </div>
                    <img
                        :src="USER_INFO.avatar || defaultAvatar"
                        alt
                        class="userAvatar cursor"
                        slot="reference"
                    />
                  </el-popover>
                  <router-link to="/console/account/userInfo" style="color:#fff">控制台</router-link>
                </span>
        <el-autocomplete
            class="top-header-search"
            v-model="searchVal"
            :fetch-suggestions="querySearchCompanyName"
            placeholder="搜索"
            :trigger-on-focus="false"
        >
          <i
              slot="suffix"
              class="el-icon-search cursor"
              style="margin-right: 5px"
              @click="searchContent"
          ></i
          ></el-autocomplete>
      </div>
    </div>
    <div class="navMenuLink">
      <div class="content_area">
        <navMenuItem/>
      </div>
    </div>
  </div>
</template>

<script>
import searchCompany from "~tcb/components/company/unit/searchCompany";
import navMenuItem from "~tcb/components/layouts/navMenuItem";

export default {
  components: {searchCompany, navMenuItem},
  data() {
    return {
      visible: false,
      defaultAvatar:
          "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/headportrait.png",
      activeIndex: "/",
      menuLogo:
          "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scb/logo.png",
      searchVal: "",
      zhReg: /^[\u4e00-\u9fa5]+$/i,
      dialogVisible: false,
    };
  },
  methods: {
    logOut() {
      this.$store.commit("baseStore/CLEAR_USER_INFO");
      this.$router.push("/sign");
    },
    // 搜索框
    searchContent() {
      if (this.searchVal) {
        this.$router.push({
          path: "/companyCatalog",
          query: {
            parameter: this._encode({
              searchVal: this.searchVal,
            }),
          },
        });
      } else {
        this.$router.push("/companyCatalog");
      }
    },
    async querySearchCompanyName(queryString, cb) {
      let params = {};
      params.company_name = queryString;
      params.source = this.PJSource;
      params.usage = 2
      params.is_vip = 1
      let res = await this.$store.dispatch(
          "baseStore/company_associateSearch",
          params
      );
      let values;
      if (this.zhReg.test(queryString)) {
        values = res.data.map((item) => {
          item.value = item.name_zh;
          return item;
        });
      } else {
        // 输入是英文或者是数字
        values = res.data.map((item) => {
          if (item.name_en) {
            // 返回数据中，英文有值
            item.value = item.name_en;
          } else {
            item.value = item.name_zh;
          }
          return item;
        });
      }
      cb(values);
    },
    mounted() {
    },
  }
}
</script>

<style scoped lang="less">
.navMenu {
  width: 100%;
  background: #fff;
  .right-top{
    display: flex;
    align-items: center;
    .userAvatar {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      border-radius: 15px;
    }
    a{
      color: #333!important;
    }
  }


  .topHeader {
    padding: 14px 0;
    display: flex;

    .topHeader-left {
      padding-right: 70px;

      img {
        width: 177px;
        height: 97px;
      }
    }

    .topHeader-center {
      padding-top: 35px;
      flex: 1;

      img {
        width: 559px;
        height: 55px;
      }
    }

    .topHeader-right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 340px;
      /deep/ .el-divider--vertical{
        background-color: #000;
      }
      .right-top{
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }

      /deep/ .top-header-search{
        margin-top: 27px;
        .el-input__inner{
          width: 340px;
          height: 48px;
          background: #FFFFFF;
          border: 1px solid #DDDDDD;
          border-radius: 24px;
          overflow: hidden;
        }
        .el-input__suffix{
          width: 64px;
          height: 48px;
          background: #0E6A37;
          right: 0;
          border-radius: 0 24px 24px 0;
          .el-icon-search{
            font-size:26px;
            color: #fff;
            margin-top: 12px;
          }
        }
      }
    }
  }

  .navMenuLink {
    width: 100%;
    height: 60px;
    background: #128144;
  }
}
</style>
<style lang="less">
.el-menu--collapse .el-menu .el-submenu,
.el-menu--popup {
  min-width: 130px !important;
  text-align: left!important;
}
.navMenuselfAccountInfo {
  p {
    line-height: 28px;
  }
  .navMenuselfAccountInfoBtn {
    margin-top: 8px;
  }
}
</style>
