<template>
  <div class="wrapper">
    <el-menu
        class="el-menu-atf"
        :unique-opened="true"
        mode="horizontal"
        :default-active="activeIndex"
        menu-trigger="click"
        @select="selectMenu"
    >
      <template v-for="(item, index) in navData">
        <el-submenu
            :key="index"
            :index="item.path"
            v-if="item.children"
            popper-class="myHomeMenuPopper"
        >
          <template slot="title">
            {{ item.title }}
          </template>
          <template v-for="(ee, vv) in item.children">
            <el-submenu
                :key="vv"
                :index="ee.path"
                v-if="ee.children"
                popper-class="myHomeMenuPopper"
            >
              <template slot="title">
                {{ ee.title }}
              </template>
              <template v-for="(eee, vvv) in ee.children">
                <el-submenu
                    :key="vvv"
                    :index="eee.path"
                    v-if="eee.children"
                    popper-class="myHomeMenuPopper"
                >
                  <template slot="title">
                    {{ eee.title }}
                  </template>
                  <template v-for="(eeee, vvvv) in eee.children">
                    <el-menu-item :key="vvvv" :index="eeee.path">
                      {{ eeee.title }}
                    </el-menu-item>
                  </template>
                </el-submenu>
                <el-menu-item :key="vvv" v-else :index="eee.path">
                  {{ eee.title }}
                </el-menu-item>
              </template>
            </el-submenu>
            <el-menu-item :key="vv" :index="ee.path" v-else>
              {{ ee.title }}
            </el-menu-item>
          </template>
        </el-submenu>
        <el-menu-item :key="index" :index="item.path" v-else>
          {{ item.title }}
        </el-menu-item>
      </template>
    </el-menu>
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="300px"
        :close-on-click-modal="true"
    >
      <div class="tc openDialog">暂未开放</div>
    </el-dialog>
    <el-dialog
        title="提示"
        :visible.sync="isMumber"
        width="300px"
        :close-on-click-modal="true"
    >
      <div class="tc openDialogs">
        <span class="blue" @click="goToMember">成为会员</span>,解锁专属权益
      </div>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="dialogVisible_company" width="300px">
      <div>
        <div class="openTitle">
          暂无企业信息, 请到控制台-企业设置 完善您的公司信息
        </div>
        <div class="tc open">
          完善企业信息<i class="el-icon-right cursor" @click="toConsole"></i>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="dialogVisible_admin" width="350px">
      <div>
        <div class="openTitle">您不是企业管理员，请联系企业管理员操作入会</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "navMenuItem",
  data() {
    return {
      activeIndex: "/",
      dialogVisible: false,
      isMumber: false,
      navData: [
        {
          title: "首页",
          path: "/",
        },
        {
          title: "协会概况",
          path: "/association",
          children: [
            {
              title: "协会简介",
              path: "/survey",
            },
            {
              title: "组织机构",
              path: "/survey/framework",
            },
            {
              title: "机构职能",
              path: "/survey/constitution",
            },
            {
              title: "协会章程",
              path: "/survey/constitution",
            },
          ],
        },
        {
          title: "资讯动态",
          path: "/regulations",
          children: [
            {
              title: "图片新闻",
              path: "/activitys?content_type=72",
            },
            {
              title: "政策资讯",
              path: "/activitys?content_type=4",
            },
            {
              title: "党建动态",
              path: "/activitys?content_type=13",
            },
            {
              title: "行业动态",
              path: "/activitys?content_type=3",
            },
          ],
        },
        {
          title: "行业自律",
          path: "/member",
          children: [
            {
              title: "规范申报",
              path: "/activitys?content_type=48",
            },
            {
              title: "阳光价格",
              path: "/activitys?content_type=49",
            },
            {
              title: "行业竞赛",
              path: "/activitys?content_type=50",
            },
            {
              title: "标准化建设",
              path: "/activitys?content_type=51",
            },
            {
              title: "公示专栏",
              path: "#hangye",
            },
          ],
        },
        {
          title: "协会服务",
          path: "/centre",
          children: [
            {
              title: "下载专区",
              path: "/downloadSection",
            },
            {
              title: "活动通知",
              path: "/activitys?content_type=1",
            },
            {
              title: "专业知识",
              path: "/activitys?content_type=37",
            },
            {
              title: "智慧通关",
              path: "/activitys?content_type=52",
            },
            {
              title: "经贸交流",
              path: "/activitys?content_type=40",
            },
            // {
            //   title: "合作招募",
            //   path: "#server",
            // },
          ],
        },
        {
          title: "合作共赢",
          path: "/college",
          children: [
            {
              title: "合作招募",
              path: "#cooperation",
            },
            {
              title: "推荐服务",
              path: "#cooperation",
            },
          ],
        },
      ],
      dialogVisible_company: false,
      dialogVisible_admin: false,
    };
  },
  watch: {
    $route(nv) {
      this.handleActive(nv.path);
    },
  },
  mounted() {
  },
  methods: {
    selectMenu(index, path) {
      if (index.indexOf("http") !== -1) {
        window.open(index);
        return;
      }
      if (index === "/talents/library") {
        if (!this.USER_INFO || !this.USER_INFO.aila_no) {
          this.isMumber = true;
          return;
        }
      }
      if (index === "/memberShip") {
        if (!this.estimateUserInfo(true, true, !this.USER_INFO.is_admin)) {
          return;
        }
      }
      if (index == "") {
        this.dialogVisible = true;
        return;
      }
      this.$router.push(index);
    },
    estimateUserInfo(sign = true, company = true, is_admin = false) {
      if (!this.USER_INFO && sign) {
        this.$router.push({
          path: "/sign",
        });
        return false;
      } else if (!this.USER_INFO.company_id && company) {
        this.dialogVisible_company = true;
        return false;
      } else if (is_admin) {
        this.dialogVisible_admin = true;
        return false;
      } else {
        return true;
      }
    },
    toConsole() {
      this.$router.push({path: "/console/company/basic"})
    },
    handleActive(path) {
      if (path.indexOf("survey") !== -1) {
        this.activeIndex = path.substring(0, 7);
      } else {
        this.activeIndex = path;
      }
    },
    goToMember() {
      this.isMumber = false;
      this.$router.push("/memberShip");
    },
  },
};
</script>

<style scoped lang="less">
.wrapper {
  /deep/  .el-menu--horizontal>.el-menu-item:not(.is-disabled):focus, .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover {
    background-color: #128144;
    color: #fff;
  }
  /deep/ .el-menu {
    display: flex;
    justify-content: space-between;
    background: #128144;
    .el-submenu.is-active {
      .el-submenu__title{
        color: #fff;
      }
    }
    .el-submenu:focus{
      .el-submenu__title{
        color: #fff;
      }
    }
    .el-menu-item{
      font-size: 18px;
      color: #9ac0a7;
    }
    .el-submenu__title{
      font-size: 18px;
      color: #9ac0a7;
      &:hover{
        background: #128144;
        color: #fff;
      }
      &:focus{
        background: #128144;
        color: #fff;
      }
    }
    .is-active{
      color: #fff;
    }
  }

}
</style>
