<template>
  <div class="siffaPageBottom">
    <div class="top">
      <div class="contact_follow clearfix content_area">
        <div class="contact">
          <h2>联系我们</h2>
          <div class="address">
            地址：天津市河西区南京路39号天津国际贸易中心B座3002室
          </div>
          <div class="tel">
            电话：022-87285720
          </div>
          <div>
            传真：022-87285686
          </div>
        </div>

        <div class="follow">
          <h2>关注我们</h2>
          <ul>
            <li v-for="(item, i) in QRcode_arr" :key="i">
              <el-image :src="item.url" fit="contain"></el-image>
              <div class="tc">{{ item.name }}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="bottom tc">
      <p>
        <a href="https://beian.miit.gov.cn"></a><img class="beianImg"
          src="https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/gongan.png" alt="" /><a
          href="https://beian.miit.gov.cn">津ICP备2022003223号-1</a>
      </p>
      Copyright@2021 天津报关协会 版权所有
      <a href="https://ailaworld.com.cn/">AiLaworld.com</a>
    </div>
  </div>
</template>

<script>
import bottomContent from "~tcb/components/layouts/bottomContent";

export default {
  components: { bottomContent },
  data() {
    return {
      Links_arr: [
        {
          lable: "上海海关",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href.png",
          href: "http://shanghai.customs.gov.cn/",
        },
        {
          lable: "科越信息",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href1.png",
          href: "http://www.etongguan.com/",
        },
        {
          lable: "上海出入境检验检疫局",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href2.png",
          href: "http://www.shciq.gov.cn/",
        },
        {
          lable: "中国上海",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href3.png",
          href: "http://www.shanghai.gov.cn/",
        },
        {
          lable: "上海市商务委员会",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href4.png",
          href: "https://sww.sh.gov.cn/",
        },
        {
          lable: "海关总署",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href5.png",
          href: "http://www.customs.gov.cn/",
        },
        {
          lable: "AiLa",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href6.png",
          href: "https://www.ailaworld.com.cn",
        },
      ],
      contact_arr: [
        {
          lable: "协会地址：",
          content: "上海市杨树浦路18号（上海航运交易所大楼）2207室",
        },

        {
          lable: "邮箱：",
          content: "service@scba.com.cn",
        },
      ],
      tel: [
        {
          lable: "秘书处总机：",
          content: "021-65374744",
        },
        {
          lable: "入会联系：",
          content: "021-68892891",
        },
        {
          lable: "业务咨询：",
          content: "021-68892892",
        },
        {
          lable: "培训联系：",
          content: "021-68892894",
        },
        {
          lable: "赞助联系：",
          content: "021-68892893",
        },
        {
          lable: "跨境贸易分会联系：",
          content: "021-20953528",
        },
        {
          lable: "空运分会联系：",
          content: "021-58102609",
        },
      ],
      QRcode_arr: [
        {
          name: "扫码关注",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tcba/home/qcprominigron.png",
        },
        {
          name: "扫码订阅",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tcba/home/qcgongzhonghao.png",
        },
      ],
    };
  },
  methods: {
    link_click(row) {
    },
    toHref(item) {
      window.open(item.href);
    },
  },
};
</script>

<style scoped lang="less">
.siffaPageBottom {
  width: 100%;
  background: #4A5A81;

  .top {
    background: #F2F2F2;
    height: 322px;

    .contact_follow {
      padding-top: 60px;
      display: flex;

      .contact {
        font-size: 14px;
        font-weight: 400;
        color: #333333;

        .address {
          margin-top: 36px;
        }

        .tel {
          padding-top: 15px;
        }
      }

      h2 {
        font-size: 18px;
        font-weight: 400;
        color: #333333;
      }

      .contact {
        padding-bottom: 26px;
        width: 1070px;

        ul {
          padding-top: 26px;

          li {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #DCDFE6;
            line-height: 30px;
          }
        }
      }

      .follow {
        min-width: 200px;
        color: #fff;

        ul {
          display: flex;
          padding-top: 36px;

          li {
            margin-right: 20px;
            width: 130px;
            height: 130px;

            img {
              width: 130px;
              height: 130px;
            }
          }
        }
      }
    }
  }

  .bottom {
    // height: 48px;
    background: #E2E2E2;
    // line-height: 48px;
    padding: 20px 0;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    a{
      color: #666666;
    }
  }
}</style>
